import styled from 'styled-components';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100vw;

  overflow: hidden;

  @media print {
    display: block;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;

  @media print {
    display: block;
  }
`;

export const VideoContainer = styled.div`
  @media (min-width: ${Sizes.breakPointDesktop}) {
    width: 70%;
  }

  width: 100%;
  height: auto;
`;

export const VideoTitle = styled.h1`
  margin-top: 0;
  padding: 10px;
  font-size: 20px;
  color: ${DeprecatedTokens.dark};
  font-weight: 400;
`;
