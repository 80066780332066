import styled from 'styled-components';
import { DeprecatedTokens } from '@visikon/spine/src/styles/tokens/deprecated-tokens';
import { Sizes } from '@visikon/spine/src/styles/tokens/sizeConstants';
import { Tokens } from '@visikon/spine/src/styles/tokens';
import color = Tokens.color;

export const FlexWrapper = styled.div<{ isList?: boolean }>`
  display: flex;

  ${({ isList }) =>
    isList
      ? `flex-direction: column;`
      : `flex-wrap: wrap;
         width: 100%;
         line-height: 1.5;
         gap: 1.2em;
         flex-grow: 1;`}
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  flex-grow: 1;

  &:last-child {
    padding-bottom: 37px;
    border-bottom: none;
  }
`;

export const StepContentWrapper = styled.div<{ isFirstStep?: boolean }>`
  width: 100%;
  padding-top: ${({ isFirstStep }) => (isFirstStep ? '40px' : '100px')};
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  @media all and (max-width: ${Sizes.breakPointMid}) {
    padding-top: ${({ isFirstStep }) => (isFirstStep ? '80px' : '120px')};
  }

  @media all and (max-width: ${Sizes.breakPointSmall}) {
    padding-top: ${({ isFirstStep }) => (isFirstStep ? '44px' : '80px')};
  }
`;
export const TextWrapper = styled.div`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  padding-bottom: 0;
  flex: 1;
`;

export const MediaWrapper = styled.div`
  flex: 2.6;
  display: flex;
  align-items: flex-end;

  & > img,
  & > video {
    width: 100%;
    border: solid 1px #0000000d;
    border-radius: 5px;
  }

  @media (max-width: ${Sizes.breakPoint}) {
    width: unset;
    min-width: 50%;
    max-width: 100%;
  }
`;

export const TextHeader = styled.div`
  padding-bottom: 4px;
  color: ${Tokens.colors.primary['500']};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${Sizes.breakPoint}) {
    position: relative;
  }
`;

export const TextDescription = styled.div`
  padding-bottom: 24px;
  color: ${DeprecatedTokens.dark};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  @media (max-width: ${Sizes.breakPoint}) {
    position: relative;
  }
`;

export const SectionTitle = styled.h2`
  color: ${color.primary};
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: inherit;
`;

export const SectionWrapper = styled.section`
  padding: 0;
  margin: 0.5em 0 1em;
`;
