import { useBackbone } from '@visikon/backbone';
import { START_CODE_ROUTE } from '../screens/startcode/StartCodeRouter';

export const useIsReferencedUser = function () {
  return useBackbone((state) => state.user?.referencedUser);
};

export function isStartAuthOrSetUserRoute() {
  return window.location.pathname.startsWith(START_CODE_ROUTE);
}
