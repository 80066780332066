import { useDialogOrchestratorContext } from '@visikon/backbone';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import { Button, Dialog, DialogActionContainer, DialogAnimations, DialogBody, DialogHeader } from '@visikon/spine';
import { useEventHandler } from '@visikon/utils';
import { useEffect, useRef } from 'react';
import { i18N, I18N } from '../../internationalization/AppText';
import { ErrorMessage } from '../login/LoginContainer.styles';

interface AuthMergeFailedDialogProps {
  error: TextKeys;
}

export function AuthMergeFailedDialog({ error }: AuthMergeFailedDialogProps) {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dialog = useDialogOrchestratorContext();

  // When the dialog orchestrator mounts the dialog, show it
  useEffect(() => {
    dialogRef.current?.showModal();
  }, []);

  // When the dialog HTML element is closed
  // also close the dialog via the dialog orchestrator
  useEventHandler(dialogRef, 'close', () => {
    dialog.close();
  });

  return (
    <Dialog _ref={dialogRef} className={DialogAnimations.SlideDown}>
      <DialogHeader>{i18N('error')}</DialogHeader>
      <DialogBody>
        <p role="alert">
          <I18N text="link_auth_merge_error" />
        </p>
        <ErrorMessage>
          <I18N text={error} />
        </ErrorMessage>
      </DialogBody>
      <DialogActionContainer>
        <Button size="small" onClick={() => dialogRef.current?.close()}>
          <I18N text="close" />
        </Button>
      </DialogActionContainer>
    </Dialog>
  );
}
