import styled from 'styled-components';

export const Hr = styled.hr<{ color: string }>`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${(props) => props.color};
  margin: 1em 0;
  padding: 0;
`;
