import { Suspense, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutAndSetAuthCode } from '../../actions/authActions';
import { tracker } from '@visikon/tracker/src';
import { backbone, DialogOrchestrator, DialogPrioity } from '@visikon/backbone/src';
import { ROOT_ROUTE } from '../../components/MainContent';
import { useIsLoggedIn } from '@visikon/backbone/src/selectors';
import { Spinner } from '../../common/Spinner';
import { useMergeUserMutation } from '../../api/auth-api';
import { AuthMergeFailedDialog } from './AuthMergeFailedDialog';
import { TranslatableError } from '../../api/auth-api.models';
import { HOME_ROUTE } from '../home/HomeScreen';
import { TextKeys } from '@visikon/core-models/i18n/translations';

export const AUTH_CODE_ROUTE = '/auth';
export const AUTH_CODE_PATH = `${AUTH_CODE_ROUTE}/:code`;

// TODO: Should we add back devices ids?
export function AuthRouter() {
  const authReady = backbone.store((state) => state.authInitialized);
  const { code } = useParams<{ code: string }>();
  const loggedIn = useIsLoggedIn();

  useEffect(() => tracker.trackScreen('AuthCode'), []);

  // To avoid a race condition, we need to wait for the auth state to be loaded
  if (!authReady) {
    console.debug('Waiting for auth state');
    return <Spinner />;
  }

  return <Suspense fallback={<Spinner />}>{loggedIn ? <MergeAuthCode code={code} /> : <SetAuthCode code={code} />}</Suspense>;
}

function MergeAuthCode({ code }: { code: string }) {
  const history = useHistory();
  const mergeMutation = useMergeUserMutation();

  useEffect(() => {
    mergeMutation
      .mutateAsync({ code })
      .then(() => {
        tracker.trackEvent('Auth', 'AuthRouter', 'Merge', 'Success');
      })
      .catch((err) => {
        const translatableError: TextKeys = err instanceof TranslatableError ? err.error : 'merge_error_unknown_error';

        // If the error is that the code is already used, don't show an error dialog
        if (translatableError === 'merge_error_same_code') {
          tracker.trackEvent('Auth', 'AuthRouter', 'Merge', 'SameCode');
          return;
        }

        DialogOrchestrator.queue({
          prioity: DialogPrioity.HIGH,
          component: AuthMergeFailedDialog,
          componentProps: { error: translatableError },
        });

        tracker.trackEvent('Auth', 'AuthRouter', 'Merge', 'Failed');
      })
      .finally(() => {
        history.push(HOME_ROUTE);
      });
  }, []);

  return null;
}

function SetAuthCode({ code }: { code: string }) {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    tracker.trackEvent('Auth', 'AuthRouter', 'SetAuthCode');
    dispatch(logoutAndSetAuthCode(code));
    history.replace(ROOT_ROUTE);
  }, []);

  return <Spinner />;
}
