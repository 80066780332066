import { createContext, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { create } from 'zustand';
import { usePrevious } from '@visikon/utils';

export enum DialogPrioity {
  // IMMEDIATE,
  HIGH,
  NORMAL,
  LOW,
}

enum DialogState {
  QUEUED,
  DISPLAYING,
  CLOSED,
}

interface QueuedDialog<Props = any> {
  id: number;
  state: DialogState;
  prioity: DialogPrioity;
  component: React.ComponentType<Props>;
  componentProps: Props;
}

interface DialogStore {
  dialogsEnabled: boolean;
  queue: QueuedDialog[];
}

let DIALOG_ID_INCREMENT = 0;
const store = create<DialogStore>(() => ({
  dialogsEnabled: true,
  queue: [],
}));

type QueueDialogOptions = Omit<QueuedDialog, 'state' | 'componentProps' | 'id'> & Partial<Pick<QueuedDialog, 'componentProps'>>;

export function queue(...dialogs: QueueDialogOptions[]): QueuedDialog[] {
  const queueItems: QueuedDialog[] = dialogs.map((options) => ({
    componentProps: {},
    ...options,
    state: DialogState.QUEUED,
    id: DIALOG_ID_INCREMENT++,
  }));

  const getPrioity = (d: QueuedDialog) => (d.state === DialogState.DISPLAYING ? -Infinity : d.prioity);
  store.setState((state) => {
    const newQueue = [...state.queue, ...queueItems].sort((a, b) => {
      return getPrioity(a) - getPrioity(b);
    });

    return { queue: newQueue };
  });

  return queueItems;
}

const DialogOrchestratorContext = createContext<QueuedDialog | undefined>(undefined);

export function useDialogOrchestratorContext() {
  const dialogContext = useContext(DialogOrchestratorContext);

  if (!dialogContext) {
    throw new Error('DialogContext is only avaible when using DialogOrchestrator');
  }

  const close = () => {
    store.setState((state) => {
      const queue = state.queue.filter((dialog) => dialog !== dialogContext);
      dialogContext.state = DialogState.CLOSED;

      return { queue };
    });
  };

  return { ...dialogContext, close };
}

function useConsumeDialog() {
  const currentDialog = store<QueuedDialog | undefined>((state) => state.queue[0]);
  const previousDialog = usePrevious(currentDialog);

  useEffect(() => {
    if (currentDialog) {
      currentDialog.state = DialogState.DISPLAYING;
    }

    // If the previous dialog got interupted by the current dialog we reset the state back to queued
    if (previousDialog?.state === DialogState.DISPLAYING) {
      previousDialog.state = DialogState.QUEUED;
    }
  }, [currentDialog]);

  return currentDialog;
}

function Renderer() {
  const dialog = useConsumeDialog();

  // Don't render anything if dialog queue is empty
  if (!dialog) return null;

  const { component: Component, componentProps } = dialog;

  return createPortal(
    <DialogOrchestratorContext.Provider value={dialog}>
      <Component key={`ORCHESTRATOR_DIALOG_${dialog.id}`} {...componentProps} />
    </DialogOrchestratorContext.Provider>,
    document.body,
  );
}

export const DialogOrchestrator = {
  queue,
  Renderer,
};
