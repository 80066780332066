import { CountryBadge } from './CountryBadge';
import * as React from 'react';
import { Country, Countries } from '@visikon/core-models/i18n/languages';
import { useCountry } from '../common/selectors';
import { Button, Dialog, DialogBody, DialogHeader } from '@visikon/spine';
import { I18N } from './AppText';
import { i18n } from './i18n';
import { TextKeys } from '@visikon/core-models/i18n/translations';
import styled from 'styled-components';
import { Tokens } from '@visikon/spine/src/styles/tokens/colors';
import color = Tokens.color;

type Props = {
  languageCodes?: string[];
  onSelectCountry?: (country: Country | null) => void;
};

const CountryBadgeBordered = styled.button`
  padding: 12px;
  border: 1px solid ${color.border};
  border-radius: 6px;
  background-color: #fcfcfc;
  cursor: pointer;
  margin-bottom: 16px;
  &:hover {
    background-color: #f7f7f7;
  }
`;
const TextBody2 = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 24px 0px;
`;
export function LanguageSelectorModal(props: Props) {
  const country = useCountry();
  const dialogRef = React.useRef<HTMLDialogElement>(null);
  const { languageCodes, onSelectCountry } = props;
  const onSelectLanguage = (country: Country | null) => {
    if (onSelectCountry) {
      onSelectCountry(country);
    }
  };

  // Tell the parent that no country was chosen
  const onDialogClose = () => onSelectLanguage(null);

  React.useEffect(() => {
    // Making sure that the buttons are not focused when the modal is opened because of the ugly outline
    const buttons = document.querySelectorAll('button');
    buttons.forEach((btn) => btn.blur());
    dialogRef.current?.showModal();
  }, []);

  const countries = Countries.filter((country) => !languageCodes || languageCodes.find((countryCode) => countryCode === country.languageCode)).sort(
    (c1, c2) => c1.languageCode.localeCompare(c2.languageCode),
  );

  // filter the countries by their language code -remove duplicates
  // findIndex returns the index of the first element in the array that satisfies the provided testing function. Otherwise, it returns -1, indicating that no element passed the test.
  const uniqueCountries = countries.filter((country, index, self) => index === self.findIndex((t) => t.languageCode === country.languageCode));

  const countryBadges = uniqueCountries.map((country, index) => (
    <CountryBadgeBordered key={`badge${country.name}${index}`} onClick={() => onSelectLanguage(country)}>
      <CountryBadge country={country} showLegend={true} imgWidth={'32px'} showArrow={true} />
    </CountryBadgeBordered>
  ));

  let translatedLanguage = i18n(country.language as TextKeys, country.languageCode);
  translatedLanguage = translatedLanguage.charAt(0).toLowerCase() + translatedLanguage.slice(1);
  const languageReplacement = { $LANGUAGE: translatedLanguage };

  return (
    <Dialog _ref={dialogRef} onClose={onDialogClose}>
      <DialogHeader>{i18n('new_language_missing_translation_title', country.languageCode)}</DialogHeader>
      <DialogBody>
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <I18N text={'new_language_missing_translation_body'} replace={languageReplacement} />
          <TextBody2>
            <I18N text={'new_language_missing_translation_body2'} replace={languageReplacement} />
          </TextBody2>
          {countryBadges}
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
            <Button variant={'ghost'} size="small" style={{ width: '100%' }} onClick={() => onSelectLanguage(null)}>
              <I18N text={'go_back'} />
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
}
